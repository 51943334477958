.video-contain {
  // On desktop, force the height of the container so the video overflows
  // both the top and bottom
  @apply lg:h-[40vw];

  // On mobile, height is dependent on the height of the video, we put
  // the video inside the waves rather than overflowing the top/bottom
  @apply w-full px-6 py-6 md:px-24 lg:py-0;
}

// Video content container
.video {
  background-color: #2c2c2c;
  aspect-ratio: 16/9;
  @apply relative mx-auto w-full overflow-hidden rounded-lg shadow-2xl md:outline-4 max-w-[1450px];
  @apply lg:-top-28;
}
