.persona {
  @apply block w-full flex-col items-center justify-center rounded-md border-2 py-2 text-center text-lg lg:inline-flex lg:aspect-square lg:h-36 lg:w-auto;

  border-color: var(--persona-color);
  color: var(--persona-color);
  transition: transform 0.1s ease-in-out;

  &:hover {
    background-color: var(--persona-color);
    color: white;
    transition: transform 0.1s ease-in-out;
    transform: scale(1.04) rotate(var(--persona-rotation));
  }
}
