.hero {
  @apply flex flex-col items-center xl:justify-center;
  @apply pt-16 pb-6 px-6;
}

.heroContent {
  @apply max-w-[800px] space-y-8;
}

.heroSubtitle {
  // Base
  @apply text-center text-xl xl:text-2xl font-normal;

  // Text shadow (in case of overlay)
  // color: black;
  // text-shadow: 0px 5px 40px rgba(255, 255, 255, 1),
  // 0 0 2px rgba(255, 255, 255, 1);
}

.heroTextCentered {
  @apply text-center text-4xl xl:text-6xl font-extrabold;

  // Text shadow (in case of overlay)
  // color: black;
  // text-shadow: 0px 5px 40px rgba(255, 255, 255, 1),
  // 0 0 2px rgba(255, 255, 255, 1);
}

