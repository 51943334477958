.features {
  @apply w-full py-8;
  @apply max-w-[1300px];
  @apply mx-auto;
  @apply text-black;
  @apply px-10;
}

.featuresImage {
  @apply w-full md:w-[500px] rounded-[12px];
  @apply mt-3 md:mt-0;
}
