.nav {
  // Applies only to the top-level links in the narbar on desktop
  a.topLevelLink {
    // Padding here creates a button effect and makes it so you
    // don't have to hover directly over the text to click the button
    @apply mx-0.5 rounded-md px-3 py-2;
    &:hover,
    &[data-state='open'] {
      @apply bg-slate-200/60;
    }
  }

  // Applies to all links, including ones inside dropdowns
  a {
    @apply text-slate-700;
    &:hover,
    &[data-state='open'] {
      @apply text-slate-900;
    }
  }
}
