// Outer container
.container {
  @apply w-screen;

  // Give extra space for waves on mobile
  @apply mt-6 md:mt-0;
}

// Waves image at the top
.waves {
  display: block;
  width: 100%;
  margin-bottom: -2px; // fix display glitch
  user-select: none;
}

// Full-width box to apply same bg color as waves
.content {
  background-color: #2c2c2c;
}
